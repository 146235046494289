import {createContactFetcher} from "./contact-fetcher";
import {createContactFilterGroup} from "./contact-filter-group";
import {debounceFlowByEventCycles} from "./flow-batch-by-event-cycles";
import {createCombinedFlow, createReadWriteFlow} from "./flow";
import {createOrderedFlightControllerForFlow} from "./flow-ordered-flight-controller";
import {createUserInputsStore} from "./user-inputs-store";

/**
 * Subscribes to the updates of the filter group and transforms them into contact responses.
 */
export const createFilteredContactFetcher = (
  fetcher: ReturnType<typeof createContactFetcher>,
  filterGroup: ReturnType<typeof createContactFilterGroup>,
  loadingIndicator: HTMLElement
) => {
  const userInputStore = createUserInputsStore();
  const fetchFlightController = createOrderedFlightControllerForFlow(
    userInputStore.wrapChange((userInputs, abortSignal) =>
      fetcher(userInputs, abortSignal)
    )
  );

  const [forceUpdates, writeForcedUpdate, _] =
    createReadWriteFlow<"forced update">();

  return {
    forceUpdate: () => {
      writeForcedUpdate(Promise.resolve("forced update"));
    },
    updates: createCombinedFlow(forceUpdates, filterGroup.updates)
      .each(async (update) =>
        update === "forced update"
          ? userInputStore.requestReset()
          : userInputStore.requestChange(update)
      )
      // Debounce very rapid updates to reduce api calls
      .with(debounceFlowByEventCycles)
      .with(fetchFlightController.runway)
      .filter(
        fetchFlightController.createFailureHandler((fetchFailure) => {
          console.error("error during fetch for contact list", {
            error: fetchFailure.data,
          });
          // TODO: this class is added and removed in 6 different places in different files. Resolve this duplication!
          loadingIndicator.classList.remove(
            "filterable-contact-list__loading-overlay--visible"
          );
        })
      )
      // if multiple fetches complete close to each other,
      // only render the latest and throw away the older responses.
      .with(debounceFlowByEventCycles)
      .each(async (fetchResult) => fetchResult.data)
      .take(async () => {
        loadingIndicator.classList.remove(
          "filterable-contact-list__loading-overlay--visible"
        );
      })
      .take(async (data) => {
        filterGroup.update(data.uncommittedInputs);
      })
      .each(async (data) => ({
        userInputs: data.usedInputs,
        response: data.response,
      })),
  };
};
