import type {Contact} from "./api-types";

type ContactListType = "all" | "general" | "specific"

const renderContact = (contact: Contact): HTMLDivElement => {
  // create elements
  const root = document.createElement('div')
  root.classList.add("filterable-contact-list__contact__wrapper")

  const image = document.createElement('img')
  image.classList.add("filterable-contact-list__contact__image")

  const name = document.createElement('div')
  name.classList.add("filterable-contact-list__contact__name")
  const street = document.createElement("div")
  street.classList.add("filterable-contact-list__contact__street")
  const city = document.createElement("div")
  city.classList.add("filterable-contact-list__contact__city")
  const country = document.createElement("div")
  country.classList.add("filterable-contact-list__contact__country")

  const responsibilitiesWrapper = document.createElement("div")
  responsibilitiesWrapper.classList.add("filterable-contact-list__contact__responsibilities")

  const phone = document.createElement('a')
  phone.classList.add("filterable-contact-list__contact__phone")
  const mobile = document.createElement('a')
  mobile.classList.add("filterable-contact-list__contact__mobile")
  const fax = document.createElement('div')
  fax.classList.add("filterable-contact-list__contact__fax")
  const mail = document.createElement('a')
  mail.classList.add("filterable-contact-list__contact__mail")

  // create contact icons
  const phoneIcon = document.createElement('i')
  phoneIcon.classList.add("filterable-contact-list__contact__icon")
  phoneIcon.classList.add("saf-icon-phone")
  const mobileIcon = document.createElement('i')
  mobileIcon.classList.add("filterable-contact-list__contact__icon")
  mobileIcon.classList.add("saf-icon-mobile")
  const faxIcon = document.createElement('i')
  faxIcon.classList.add("filterable-contact-list__contact__icon")
  faxIcon.classList.add("saf-icon-fax")
  const mailIcon = document.createElement('i')
  mailIcon.classList.add("filterable-contact-list__contact__icon")
  mailIcon.classList.add("saf-icon-email")


  const filterableContactList = document.getElementById('filterable-contact-list');

  if (filterableContactList instanceof HTMLElement) {
    const placeholderImage = filterableContactList.dataset['contactplacholderimage'];
    if (placeholderImage !== undefined) {
      image.src = placeholderImage;
    }
  }

  if (contact.imageUrl) {
    // set src or data-usercentrics-src based on user consent
    if (window.UC_UI?.getServicesBaseInfo().find((element: any) => element.name == "Salesforce")?.consent.status === true) {
      image.src = contact.imageUrl
    } else {
      image.dataset["usercentricsSrc"] = contact.imageUrl // is set to src via external script when user accepts cookies
    }
  }

  name.append(contact.name)

  contact.street && street.append(contact.street)
  contact.postalCode && city.append(contact.postalCode + " ")
  contact.city && city.append(contact.city)
  contact.country && country.append(contact.country)

  contact.responsibilities && contact.responsibilities.map((responsibility) => {
    const responsibilityWrapper = document.createElement("div")
    responsibilityWrapper.classList.add("filterable-contact-list__contact__responsibility")
    responsibilityWrapper.append(responsibility)
    responsibilitiesWrapper.append(responsibilityWrapper)
  })

  if (contact.phone) {
    phone.append(phoneIcon)
    phone.append(contact.phone)
    phone.href = `tel:${contact.phone}`
  }
  if (contact.mobile) {
    mobile.append(mobileIcon)
    mobile.append(contact.mobile)
    mobile.href = `tel:${contact.mobile}`
  }
  if (contact.fax) {
    fax.append(faxIcon)
    fax.append(contact.fax)
  }
  if (contact.mail) {
    mail.append(mailIcon)
    mail.append(contact.mail)
    mail.href = `mailto:${contact.mail}`
  }


  // wrapper for address
  const address = document.createElement('div')
  address.classList.add("filterable-contact-list__contact__address")
  address.append(street)
  address.append(city)
  address.append(country)

  responsibilitiesWrapper.append()

  // wrapper for phone, mobile and fax
  const numbers = document.createElement('div')
  numbers.classList.add("filterable-contact-list__contact__numbers")
  numbers.append(phone)
  numbers.append(mobile)
  numbers.append(fax)
  numbers.append(mail)

  // wrapper for contact information
  const information = document.createElement('div')
  information.classList.add("filterable-contact-list__contact__information")
  information.append(name)
  if (contact.generalContact) {
    information.append(address)
  } else {
    information.append(responsibilitiesWrapper)
  }
  information.append(numbers)

  // add to contact wrapper
  root.append(image)
  root.append(information)

  return root
}

// TODO: move contactListType filter to backend business logic
export const createContactList = (container: HTMLElement, contactListType: ContactListType = "all") => {
  let previousElements: HTMLElement[] = []

  return {
    update(contacts: Contact[], message?: string) {
      let newElements: HTMLDivElement[] = [];
      // show message only if there are no contacts
      if (!contacts || contacts.length === 0) {
        const messageBox = document.createElement("div")
        messageBox.append(document.createTextNode(message ?? ""))
        newElements = [messageBox]
      } else {
        newElements = contacts.filter((contact) =>
          contactListType === "all"
          || contactListType === "general" && contact.generalContact
          || contactListType === "specific" && !contact.generalContact)
          .map((contact) => renderContact(contact))
      }
      for (const previousElement of previousElements) {
        previousElement.remove()
      }
      for (const newElement of newElements) {
        container.append(newElement)
      }
      previousElements = newElements
    }
  }
}
