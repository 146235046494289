import type {
  ContactsResponse,
  FilterInputField,
  InputFieldType,
  UserInput,
} from "./api-types";
import { createOrderedFlightControllerForFlow } from "./flow-ordered-flight-controller";

interface InputData {
  type: InputFieldType;
  value: string;
}

const exportInputMap = (map: Map<string, InputData>): UserInput[] =>
  [...map.entries()].map(([key, data]) => {
    if (data.type === "Combobox") {
      return {
        recordTypeName: key,
        selectedOption: data.value,
      };
    } else {
      return {
        recordTypeName: key,
        textInput: data.value,
      };
    }
  });

/**
 * create a new abort signal that will be aborted when any of the given signals is aborted.
 */
const combineAbortSignals = (...signals: AbortSignal[]) => {
  const abortController = new AbortController();
  for (const signal of signals) {
    signal.addEventListener("abort", () => {
      abortController.abort(signal.reason);
    });
  }
  return abortController.signal;
};

/**
 * The user input store handles {@link UserInput user input} change and set requests.
 *
 * If a change or set is requested, it'll be added to the uncommitted changes. If that change gets approved by the
 * remote, it'll be moved to the committed inputs, that hold the portion of the inputs used in the last successful
 * request which were approved by the remote.
 *
 * When a change gets committed, all future (currently in-flight) changes are checked,
 * if they can still be applied with the new filter configuration.
 */
export const createUserInputsStore = () => {
  const committedInputs = new Map<string, InputData>();

  type Change = {
    readonly key: string;
    readonly value: string;
    readonly type: InputFieldType;
    readonly abort: (reason: unknown) => void;
    readonly abortSignal: AbortSignal;
    next?: Change;
    previous?: Change;
  };

  let head: Change | undefined;

  /**
   * Requests a reset
   */
  const requestReset = () => {
    head?.abort(new Error("reset"));
    head = undefined;
    committedInputs.clear();
    return "reset" as const;
  };

  /**
   * Requests a single change
   */
  const requestChange = (input: UserInput): Change => {
    const abortController = new AbortController();
    const newChange = {
      key: input.recordTypeName,
      value: input.selectedOption ?? input.textInput ?? "",
      type: typeof input.selectedOption === "string" ? "Combobox" : "Input",
      abort(reason?: unknown) {
        abortController.abort(reason);
      },
      abortSignal: abortController.signal,
      previous: head,
    } satisfies Change;

    if (head) {
      head.next = newChange;
    }

    head = newChange;

    return newChange;
  };

  /**
   * Wraps the flight block for user inputs into a flight block for requested changes produced by
   * {@link requestReset} and {@link requestChange}
   */
  const wrapChange =
    <ResponseObject extends Pick<ContactsResponse, "filterInputFields">>(
      retrieveResponse: Parameters<
        typeof createOrderedFlightControllerForFlow<UserInput[], ResponseObject>
      >[0]
    ): Parameters<
      typeof createOrderedFlightControllerForFlow<
        ReturnType<typeof requestReset> | ReturnType<typeof requestChange>,
        {
          /**
           * this includes the inputs that where entered after this request was sent.
           */
          uncommittedInputs: FilterInputField[];
          /**
           * the inputs that are actually used in the request.
           */
          usedInputs: UserInput[];
          /**
           * the response from the remote.
           */
          response: ResponseObject;
        }
      >
    >[0] =>
      (change, flightAbortSignal) =>
        change === "reset"
          ? (async () => {
            const exportedInputs = exportInputMap(
              _exportInputsOfChange()
            );
            const response = await retrieveResponse(exportedInputs, flightAbortSignal);
            _commitInputs(exportedInputs, response.filterInputFields ?? []);
            return {
              uncommittedInputs: _getExpandedUncommittedInputs(
                response.filterInputFields
              ),
              usedInputs: exportedInputs,
              response,
            };
          })()
          : new Promise(async (resolve, reject) => {
            try {
              const abortSignal = combineAbortSignals(
                flightAbortSignal,
                change.abortSignal
              );
              abortSignal.addEventListener("abort", () =>
                reject(abortSignal.reason)
              );
              const exportedInputs = exportInputMap(
                _exportInputsOfChange(change)
              );
              const response = await retrieveResponse(exportedInputs, abortSignal);
              _abortChangesWithWrongAssumptions(
                response.filterInputFields ?? [],
                change.next
              );
              _commitInputs(exportedInputs, response.filterInputFields ?? []);
              if (change.next) {
                change.next.previous = undefined;
                change.next = undefined;
              }
              if (head === change) {
                head = undefined;
              }
              resolve({
                uncommittedInputs: _getExpandedUncommittedInputs(
                  response.filterInputFields
                ),
                usedInputs: exportedInputs,
                response,
              });
            } catch (error: unknown) {
              reject(error);
            }
          });

  const _getMostPreviousChange = (change: Change): Change =>
    change.previous ? _getMostPreviousChange(change.previous) : change;

  /**
   * Exports the user inputs based on the committed inputs with the change and its parent changes applied.
   */
  const _exportInputsOfChange = (lastChange?: Change) => {
    const inputs = new Map(committedInputs);

    let movingHead: Change | undefined = lastChange
      ? _getMostPreviousChange(lastChange)
      : undefined;
    while (movingHead) {
      inputs.set(movingHead.key, {
        type: movingHead.type,
        value: movingHead.value,
      });
      movingHead = movingHead.next;
    }

    return inputs;
  };

  /**
   * commit the given user inputs
   */
  const _commitInputs = (
    userInputs: UserInput[],
    remoteFields: FilterInputField[]
  ) => {
    committedInputs.clear();
    const fieldInputs = userInputs.filter(
      (input) =>
        !!remoteFields.find(
          (field) => field.recordTypeName === input.recordTypeName
        )
    );
    for (const input of fieldInputs) {
      committedInputs.set(input.recordTypeName, {
        value: input.selectedOption ?? input.textInput ?? "",
        type: typeof input.selectedOption === "string" ? "Combobox" : "Input",
      });
    }
  };

  const _getExpandedUncommittedInputs = (
    fields?: FilterInputField[]
  ): FilterInputField[] => {
    if (!fields) {
      return [];
    }

    const inputs = _exportInputsOfChange(head);

    return fields.map((inputField) => {
      if (inputField.inputFieldType !== "Combobox") {
        return inputField;
      }

      const overloadedValue =
        (inputField.recordTypeName &&
          inputs.get(inputField.recordTypeName)?.value) ||
        undefined;

      if (!overloadedValue) {
        return inputField;
      }

      return {
        ...inputField,
        options: inputField.options?.map((option) =>
          option.value === overloadedValue
            ? { ...option, selected: true }
            : { ...option, selected: false }
        ),
      };
    });
  };

  /**
   * Given the truth,
   * check if the assumptions of the change (and its next changes) still apply,
   * otherwise abort the change.
   */
  const _abortChangesWithWrongAssumptions = (
    truth: FilterInputField[],
    change?: Change
  ) => {
    if (change === undefined) {
      return;
    }

    /**
     * these fields may be the same field that the given change has been created for.
     */
    const sameFieldCandidates = truth
      .filter(field => !!field.recordTypeName)
      .filter(field => field.recordTypeName === change.key)
      .filter(field => field.inputFieldType === change.type)

    for (const field of sameFieldCandidates) {
      if (field.inputFieldType === "Combobox") {
        if (!field.options) {
          continue;
        }

        for (const optionOfTruthfulField of field.options) {
          if (optionOfTruthfulField.value === change.value) {
            // this change is valid, since the Combobox option using in the change still exists.
            _abortChangesWithWrongAssumptions(truth);
            return;
          }
        }
      } else {
        // this change is valid, since the same field exists.
        _abortChangesWithWrongAssumptions(truth, change.next);
        return;
      }
    }

    // the assumption under which the change has been created is no longer valid, so this change and all
    // the changes that are based upon it, are invalid and should be aborted since they'll fail.
    let abortionHead: Change | undefined = change;
    while (abortionHead) {
      abortionHead.abort(
        new Error("Assumption of change has been proven as untruthful.")
      );
      abortionHead = abortionHead.next;
    }
  };

  return {
    requestReset,
    requestChange,
    wrapChange,
  };
};
