import type {FilterInputField} from "../api-types";
import {renderComboboxFormElement} from "./combobox";
import {renderInputFormElement} from "./input";

/**
 * Renders a filter element for the given field.
 *
 * @param field
 * @param emptyOption if not undefined, the text will be offered to the user to clear the current filter value.
 */
export const renderFilterDomNode = (field: FilterInputField, emptyOption?: string) => {
  const labelElement = document.createElement('label')
  labelElement.classList.add("filterable-contact-list__filter")
  const labelTextElement = document.createElement('span')
  labelTextElement.classList.add("filterable-contact-list__filter__label")
  labelTextElement.append(field.label ?? field.recordTypeName ?? "")
  labelElement.append(labelTextElement)

  if (field.inputFieldType === "Combobox") {
    const formElement = renderComboboxFormElement(field, emptyOption)
    labelElement.append(formElement)

    return [labelElement, formElement] as const
  }

  const [wrapperElement, formElement] = renderInputFormElement(field)
  labelElement.append(wrapperElement)

  return [labelElement, formElement] as const
}
