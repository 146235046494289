import type {Flow} from "./flow";

interface Flight<O> {
  promise: Promise<O>
  previous: Flight<O> | null
  abortFunction: () => void
}

/**
 * The flight's promise has been resolved, before it was aborted.
 */
interface LandedFlight<I, O> {
  status: "landed successfully"
  data: O
  cargo: I
}

/**
 * The flight's promise has been rejected.
 * This includes aborted flights ({@link DOMException "AbortError" DOMException}).
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/AbortController/abort
 */
interface CrashedFlight<I> {
  status: "crashed"
  data: unknown
  cargo: I
}

/**
 * A flight controller provides a basis for starting new flights and catching & handling failed ones
 * in {@link Flow Flows}.
 *
 * At its core, a flight is a promise which may has a previous flight.
 * When it is destroyed, either because it is completed or crashed,
 * the previous flight will be canceled / destroyed.
 *
 * Considerations to keep in mind:
 *
 * - A flight might not properly implement abortion, in which case
 *   it will be able to "land successfully" even after a next flight
 *   was completed.
 */
export const createOrderedFlightControllerForFlow = <I, O>(performFlight: (cargo: I, abortSignal: AbortSignal) => Promise<O>) => {
  let head: Flight<O> | null = null

  return {
    runway: (readFlow: Flow<I>) =>
      readFlow.each(async (cargo) => {
        const abortController = new AbortController()
        const flight: Flight<O> = {
          promise: performFlight(cargo, abortController.signal),
          previous: head,
          abortFunction: () => {
            abortController.abort()
            flight.previous?.abortFunction()
          }
        }
        head = flight
        try {
          const data = await flight.promise

          if (head === flight) {
            head = null
          }

          return {status: "landed successfully", data, cargo} as const
        } catch (e: unknown) {

          if (head === flight) {
            head = null
          }

          return {status: "crashed", data: e, cargo} as const
        }
      }),
    createFailureHandler: (failureHandle: (failure: CrashedFlight<I>) => void) =>
      (item: CrashedFlight<I> | LandedFlight<I, O>): item is LandedFlight<I, O> => {
        if (item.status === "landed successfully") {
          return true
        }

        failureHandle(item)
        return false
      }
  } as const
}
