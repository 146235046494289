import {type Flow, produceItemFlow} from "./flow";

/**
 * Groups all items of a flow, that were received in the same "event cycle" into a list.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/setTimeout setTimeout on MDN
 * @see https://developer.mozilla.org/en-US/docs/Web/API/HTML_DOM_API/Microtask_guide#tasks short description of the task queue on MDN
 */
export const batchFlowByEventCycles = <T>(flow: Flow<T>): Flow<Awaited<T>[]> =>
  produceItemFlow((emit, close) => {
    let itemsTakenDuringLastCycle: Awaited<T>[] = []
    let isSomeItemDuringCurrentCycleCollected = false
    let isCloseRequested = false

    flow
      .each(async (item) => {
        isSomeItemDuringCurrentCycleCollected = true
        itemsTakenDuringLastCycle.push(item)

        const taskForNextCycle = () => {
          if (!isSomeItemDuringCurrentCycleCollected) {
            return
          }

          isSomeItemDuringCurrentCycleCollected = false
          emit(itemsTakenDuringLastCycle)
          itemsTakenDuringLastCycle = []
          if (isCloseRequested) {
            close()
          }
        }

        if ('scheduler' in globalThis) {
          // use the better suited scheduler api if available
          // https://developer.mozilla.org/en-US/docs/Web/API/setTimeout#reasons_for_delays_longer_than_specified

          // spec https://wicg.github.io/scheduling-apis/#sec-scheduler
          // https://developer.mozilla.org/en-US/docs/Web/API/Prioritized_Task_Scheduling_API
          (globalThis as unknown as ({
            scheduler: {
              postTask<T>(
                task: () => T,
                options?: { priority: "user-blocking" | "user-visible" | "background" }): Promise<T>
            }
          }))
            .scheduler
            .postTask(taskForNextCycle, {priority: "user-visible"})
        } else {
          setTimeout(taskForNextCycle)
        }
      })
      .consume()
      .then(() => {
        if (isSomeItemDuringCurrentCycleCollected) {
          isCloseRequested = true
        } else {
          close()
        }
      })
  })

/**
 * After grouping up all items received during each "event cycle", the returned
 * stream will only contain the last item of each group.
 *
 * The main difference to {@link https://rxjs.dev/api/index/function/debounce}
 * (despite that it operates based on task timing rather than fixed time delays)
 * is that the last item is kept, instead of the first one.
 */
export const debounceFlowByEventCycles = <T>(readFlow: Flow<T>) =>
  readFlow.with(batchFlowByEventCycles)
    .each(async (item) => item.pop())
    .filter((value): value is Awaited<T> => value !== undefined)
