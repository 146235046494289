import type {FilterInputField} from "../api-types";

/**
 * Renders a text input filter element.
 */
export const renderInputFormElement = (field: FilterInputField) => {
  const wrapperElement = document.createElement("span")
  wrapperElement.classList.add("filterable-contact-list__input--text__wrapper")

  const inputElement = document.createElement('input')
  inputElement.classList.add("filterable-contact-list__input--text")
  const resetButtonElement = document.createElement("span")
  resetButtonElement.classList.add("saf-icon-close")
  resetButtonElement.style.display = "none"

  // hide / change reset button depending on value
  inputElement.addEventListener("input", () => {
    resetButtonElement.style.display = (inputElement.value) ? "block" : "none"
  })

  // reset input field on button click
  resetButtonElement.addEventListener("click", () => {
    inputElement.value = ""
    inputElement.dispatchEvent(new Event("input"))
  })

  if (field.textInputMinLength) {
    inputElement.maxLength = field.textInputMinLength
  }

  wrapperElement.append(inputElement)
  wrapperElement.append(resetButtonElement)

  return [wrapperElement, inputElement] as const
}
