import type {FilterInputField, FilterInputFieldOption, UserInput} from "./api-types";
import {produceItemFlow} from "./flow";
import {renderFilterDomNode} from "./filter";

const addedItemsEvent = new Event('addedItems')

const collectSelectValue = (element: HTMLSelectElement) => {
  const selectedValues = new Set<string>()

  for (const selectedOption of element.selectedOptions) {
    selectedValues.add(selectedOption.value)
  }

  return selectedValues
}

export const createContactFilter = (field: FilterInputField, loadingIndicator: HTMLElement, emptyOption: string = "...") => {
  const [rootNode, formNode] = renderFilterDomNode(field, emptyOption)
  const getCurrentText = (): UserInput => {
    return {
      recordTypeName: field.recordTypeName ?? "",
      textInput: formNode.value
    }
  }

  const getCurrentSelect = (): UserInput => {
    return {
      recordTypeName: field.recordTypeName ?? "",
      selectedOption: formNode.value
    }
  }

  const abortController = new AbortController()

  const updates = produceItemFlow<UserInput>((emit, close) => {
    const changeHandler = () => {
      if (field.inputFieldType === "Combobox") {
        emit(getCurrentSelect())
      } else if (field.inputFieldType === "Input") {
        emit(getCurrentText())
      }

      loadingIndicator.classList.add("filterable-contact-list__loading-overlay--visible")
    }

    formNode.addEventListener('change', changeHandler)
    abortController.signal.onabort = () => {
      formNode.removeEventListener('change', changeHandler)
      loadingIndicator.classList.remove("filterable-contact-list__loading-overlay--visible")
      close()
    }
  })

  return {
    node: rootNode,
    updates,
    destroy() {
      abortController.abort()
      rootNode.remove()
    },
    updateOptions(updatedOptions: FilterInputFieldOption[]) {
      if (!(formNode instanceof HTMLSelectElement)) {
        return
      }

      collectSelectValue(formNode);
      formNode
        .querySelectorAll('option')
        .forEach(option => option.remove())

      const emptyOptionElement = document.createElement('option')
      emptyOptionElement.value = ''
      emptyOptionElement.append(emptyOption)
      formNode.append(emptyOptionElement)

      for (const updatedOption of updatedOptions) {
        const optionElement = document.createElement('option')
        optionElement.selected = updatedOption.selected === true
        optionElement.value = updatedOption.value ?? ''
        optionElement.append(updatedOption.label ?? updatedOption.value ?? '')
        formNode.append(optionElement)
      }

      formNode.dispatchEvent(addedItemsEvent)
    }
  } as const
}
