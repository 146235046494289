import type {FilterInputField, UserInput} from './api-types';
import {createContactFilter} from './contact-filter';
import {createReadWriteFlow} from './flow';
import {InitChoices} from "../../choices";

const createMapOfFilterFields = (fields: FilterInputField[]) => {
  const set = new Map<string, FilterInputField>();

  for (const field of fields) {
    // TODO: so when we get filters from the api, the api is allowed to send a recordTypeName of null.
    //        But how could we send a value for such a field, if we aren't allowed to send a recordTypeName of null?
    set.set(field.recordTypeName ?? '', field);
  }

  return set;
};

/**
 * Manages a variable amount of filters.
 *
 * The values of the filters can be changed by the user.
 *
 * The configuration of the filters can be updated.
 */
export const createContactFilterGroup = (
  initialFilters: FilterInputField[],
  filterContainer: HTMLElement,
  loadingIndicator: HTMLElement,
  emptyOption?: string
) => {
  const [updates, writeUpdateJob, _] = createReadWriteFlow<UserInput>();

  const renderedFilters = new Map<
    string,
    ReturnType<typeof createContactFilter>
  >();

  const update = (filters: FilterInputField[]) => {
    const newFields = createMapOfFilterFields(filters);

    for (const [identifier, existingFilter] of renderedFilters) {
      const newField = newFields.get(identifier);
      if (!newField) {
        renderedFilters.delete(identifier);
        existingFilter.destroy();
        continue;
      }

      if (newField.options) {
        existingFilter.updateOptions(newField.options);
      }
    }

    // introduce new filters that don't exist yet
    for (const [newFieldIdentifier, newField] of newFields.entries()) {
      if (renderedFilters.has(newFieldIdentifier)) {
        continue;
      }

      const newFilter = createContactFilter(newField, loadingIndicator, emptyOption);
      renderedFilters.set(newFieldIdentifier, newFilter);
      newFilter.updates.each(async (update) =>
        writeUpdateJob(Promise.resolve(update))
      );
      filterContainer.append(newFilter.node);
    }

    InitChoices(filterContainer)
  };

  update(initialFilters);

  return {
    updates,
    update,
  } as const;
};
