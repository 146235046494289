import type {FilterInputField} from "../api-types";

/**
 * Renders a single select / combobox filter element.
 */
export const renderComboboxFormElement = (field: FilterInputField, emptyOption: string = "...") => {
  const selectElement = document.createElement('select')
  selectElement.dataset['behavior'] = 'onchange-change'
  selectElement.dataset['resettable'] = 'true'

  const emptyOptionElement = document.createElement('option')
  emptyOptionElement.value = ''
  emptyOptionElement.append(emptyOption)
  selectElement.append(emptyOptionElement)

  if (field.options) {
    field.options.forEach((option) => {
      if (!option.value) {
        return // TODO: what can we do if the option has no value?
      }

      const optionElement = document.createElement('option')
      optionElement.value = option.value ?? ''
      optionElement.selected = option.selected === true
      optionElement.append(option.label ?? option.value)

      selectElement.append(optionElement)
    })
  }

  return selectElement
}
