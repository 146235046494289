import type { ContactsResponse, UserInput } from './api-types';

export type FetcherType = "product" | "market" | "all"

/**
 * Create a function to fetch for new contacts + filters using the given form as a base.
 *
 * This way, different static values can be added to the request, without needing to handle them explicitly here.
 */
export const createContactFetcher = (formElement: HTMLFormElement, fetcherType: FetcherType = "product") => {
  const actionUri = formElement.action;

  const fieldName = `tx_contacts2_${fetcherType}contacts[userInputs]`

  return async (userInputs: UserInput[], abortSignal: AbortSignal) => {
    const data = new FormData(formElement);
    data.set(
      fieldName,
      JSON.stringify(userInputs)
    );

    const response = await fetch(actionUri, {
      method: 'POST',
      body: data,
      signal: abortSignal,
    });

    return (await response.json()) as ContactsResponse;
  };
};
